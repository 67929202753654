<template>
  <v-sheet class="transparent" v-if="mainPosition">
    <help class="help--org-chart" :helpId="36" />
    <organization-chart
      v-if="mainPosition"
      class="organization-chart"
      :pan="true"
      :datasource="mainPosition"
      @node-click="
        ''

      "
    >
      <template v-slot="{ nodeData }">
        <position-card-1
          @selectedPosition="handleSelectedPosition($event)"
          :position="nodeData"
        />
      </template>
    </organization-chart>
    <v-btn absolute bottom right small icon class="ma-5" @click="fullScreenDialog = true"
      ><v-icon>mdi-fullscreen</v-icon></v-btn
    >
    <v-dialog v-model="fullScreenDialog" height="80%" :width="dialogWidth">
      <v-card class="lighten-3">
        <organization-chart
          v-if="mainPosition && mainPosition !== undefined"
          class="dialog organization-chart"
          :pan="true"
          :zoom="true"
          :zoomin-limit="3"
          :zoomout-limit="0.1"
          :datasource="mainPosition"
          @node-click="
            ''

          "
        >
          <template v-slot="{ nodeData }">
            <position-card-1
              @selectedPosition="handleSelectedPosition($event)"
              :position="nodeData"
            />
          </template>
        </organization-chart>
        <v-btn icon absolute top right @click="fullScreenDialog = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" :min-width="dialogWidthSmall" max-width="50%">
      <chart-position-card :item.sync="selectedPosition" :loading="loadingCard" />
    </v-dialog>
  </v-sheet>
</template>
<script>
  import OrganizationChart from 'vue-organization-chart'
  import PositionCard1 from '@/components/HumanTalent/PositionCard1'
  import ChartPositionCard from '@/components/HumanTalent/ChartPositionCard'
  import 'vue-organization-chart/dist/orgchart.css'
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'

  export default {
    components: {
      OrganizationChart,
      PositionCard1,
      ChartPositionCard,
    },
    props: { typeId: { type: Number, required: true } },
    data() {
      return {
        dialog: false,
        fullScreenDialog: false,
        loadingCard: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        selectedPosition: {},
        mainPosition: {},
      }
    },
    watch: {
      company() {},
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '80%'
      },
      dialogWidthSmall() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '50%'
      },
    },
    created() {
      this.getPositions()
    },
    methods: {
      handleSelectedPosition(position) {
        this.loadingCard = true
        this.selectedPosition = {}
        this.dialog = true
        const withString = '&appends[]=users_by_company'
        this.$axios
          .get('positions/' + position.id + '?company_id=' + this.company.id + withString)
          .then((response) => {
            this.selectedPosition = response.data
            this.loadingCard = false
          })
      },
      getPositions(search) {
        let searchStr = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'positions?limit=1&children=10&position_id=0&type_id=' +
              this.typeId +
              searchStr +
              orderString
          )
          .then((response) => {
            this.mainPosition = response.data[0]
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  // .orgchart .node {
  //   margin: 3px;
  //   padding: 0px;
  //   border: none;
  //   width: 100px;
  // }
  .v-dialog {
    justify-content: center;
    display: flex;
    box-shadow: none;
  }
  .organization-chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .organization-chart tbody tr:nth-of-type(odd) {
    background-color: none !important;
  }
  .dialog.organization-chart {
    border: none;
  }
</style>
