<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="box mx-auto"
      :color="color"
      :class="{ 'on-hover': hover }"
      dark
      max-width="400"
      @click="$emit('selectedPosition', position)"
    >
      <span class="font-weight-light">{{ position.name }}</span>
    </v-card></v-hover
  >
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'

  export default {
    props: ['position'],
    data() {
      return {}
    },
    watch: {
      company() {},
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      color() {
        switch (this.company.level_id) {
          case 1:
            return '#2F5597'

          case 2:
            return '#00B0F0'

          case 3:
            return '#008EB9'

          default:
            return '#26c6da'
        }
      },
    },
    methods: {},
  }
</script>
<style lang="scss" scoped>
  .box {
    cursor: pointer;
    span {
      font-size: 12px;
    }
  }
  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 5px;
    justify-content: center;
  }
  .current-position {
    background-color: #000;
  }
  .v-application .elevation-10 {
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 40%), 0px 10px 14px 1px rgb(0 0 0 / 40%),
      0px 4px 18px 3px rgb(0 0 0 / 20%) !important;
  }
  .on-hover {
    box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 20%),
      0px 4px 18px 3px rgb(0 0 0 / 10%) !important;
  }
</style>
