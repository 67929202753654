var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"position-card","loading":_vm.loading ? _vm.loading : false}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto pa-4",attrs:{"max-width":"300","type":"card"}}):_c('div',[_c('v-card-title',[_c('h2',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.item.name))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$router.push({
                name: 'administrative/human-talent/PositionProfile',
                params: { positionId: _vm.item.id },
              })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v(" mdi-account-network ")])],1)]}}])},[_c('span',[_vm._v("Ver perfil de "+_vm._s(_vm.item.name))])])],1),(_vm.item.users_by_company.length > 0)?_c('v-divider',{attrs:{"inset":""}}):_vm._e(),(_vm.item.users_by_company.length > 0)?_c('v-subheader',{staticClass:"py-0",staticStyle:{"height":"30px"}},[_vm._v("Personal ")]):_vm._e(),(_vm.item.users_by_company.length > 0)?_c('v-row',{staticClass:"mx-0"},_vm._l((_vm.item.users_by_company),function(user,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-list-item',{staticClass:"px-0",attrs:{"dense":"","inactive":""}},[(user.avatar && user.avatar.route)?_c('v-list-item-avatar',{attrs:{"color":"grey darken-3"}},[_c('v-img',{staticClass:"elevation-6",attrs:{"alt":user.name,"src":user.avatar.route}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(user.name + ' ' + user.lastname))])],1),_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'administrative/human-talent/UserProfile',
                      params: { userId: user.id },
                    })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-tie ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver perfil de "+_vm._s(user.name))])])],1)],1)],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }