<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <horizontal-menu :menuItems="menuItems" />

      <v-row align="center">
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Puestos Autorizados'"
            :metric="stats.autorizedVacancies ? stats.autorizedVacancies.toString() : ''"
            :icon="'mdi-account-check-outline'"
            :loading="loading"
            :helpId="16"
            style="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Puestos Disponibles'"
            :metric="stats.availableVacancies ? stats.availableVacancies.toString() : ''"
            :icon="'mdi-briefcase-check-outline'"
            :loading="loading"
            :helpId="17"
            style="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Fuerza de trabajo'"
            :metric="
              stats.laborForce ? parseFloat(stats.laborForce.toFixed(1)) + '%' : ''
            "
            :icon="'mdi-sitemap'"
            :loading="loading"
            :helpId="18"
            style="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
        <v-col cols="12" sm="6" lg="3" md="4">
          <single-metric
            :title="'Manuales cargados'"
            :metric="stats.loadedManuals ? stats.loadedManuals.toString() : ''"
            :helpId="18"
            :icon="'mdi-book-open-blank-variant'"
            :loading="loading"
            style="'position: absolute; right: 5px; bottom: 5px'"
          />
        </v-col>
      </v-row>
      <org-chart :typeId="company.type_id" />
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  import OrgChart from '@/components/HumanTalent/OrgChart'
  import SingleMetric from '@/components/Stats/SingleMetric'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'HumanTalentDashboard',
    components: {
      HorizontalMenu,
      OrgChart,
      SingleMetric,
    },
    data: () => ({
      loading: false,
      stats: {},
      menuItems: [
        {
          id: 2,
          name: 'Perfiles de Cargos',
          icon: 'mdi-account-network',
          link: '/administrative/human-talent/positions',
          requiredRoles: ['super_admin', 'admin', 'admin_positions'],
        },
        {
          id: 3,
          name: 'Inventario de Procesos',
          icon: 'mdi-badge-account',
          link: '/administrative/human-talent/process',
          requiredRoles: ['super_admin', 'admin', 'admin_processes'],
        },
        {
          id: 4,
          name: 'Inventario de Manuales',
          icon: 'mdi-ballot',
          link: '/administrative/human-talent/manuals',
          requiredRoles: ['super_admin', 'admin', 'admin_processes'],
        },
        {
          id: 5,
          name: 'Gestor de Métricas',
          icon: 'mdi-account-network',
          link: '/administrative/human-talent/metrics',
          requiredRoles: ['super_admin', 'admin', 'admin_positions'],
        },
        {
          id: 6,
          name: 'Bolsa de empleo',
          icon: 'mdi-account-tie-voice',
          link: '/administrative/human-talent/job-bank',
          requiredRoles: ['super_admin', 'admin', 'admin_positions'],
        },
        // {
        //   id: 5,
        //   name: 'Desempeño de la Empresa',
        //   icon: 'mdi-gauge-full',
        //   link: '/administrative/human-talent/performance',
        // },
      ],
    }),
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    created() {
      this.getStats()
    },
    methods: {
      getStats() {
        this.loading = true
        this.$axios
          .get('stats-management/human-talent-stats?company_id=' + this.company.id)
          .then((response) => {
            this.stats = response.data
            this.loading = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .orgchart .node .title {
    font-weight: 400;
  }

  .travel-map {
    height: 400px;
  }
</style>
