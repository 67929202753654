<template data-app>
  <v-card id="position-card" :loading="loading ? loading : false">
    <v-skeleton-loader v-if="loading" class="mx-auto pa-4" max-width="300" type="card" />
    <div v-else>
      <v-card-title
        ><h2 class="text-h6">{{ item.name }}</h2>
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="blue-grey"
              v-bind="attrs"
              v-on="on"
              @click="
                $router.push({
                  name: 'administrative/human-talent/PositionProfile',
                  params: { positionId: item.id },
                })
              "
            >
              <v-icon color="grey lighten-2"> mdi-account-network </v-icon>
            </v-btn>
          </template>
          <span>Ver perfil de {{ item.name }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider inset v-if="item.users_by_company.length > 0" />
      <v-subheader
        class="py-0"
        style="height: 30px"
        v-if="item.users_by_company.length > 0"
        >Personal
      </v-subheader>

      <v-row class="mx-0" v-if="item.users_by_company.length > 0">
        <v-col cols="12" v-for="(user, index) in item.users_by_company" :key="index">
          <v-list-item class="px-0" dense inactive>
            <v-list-item-avatar
              color="grey darken-3"
              v-if="user.avatar && user.avatar.route"
            >
              <v-img class="elevation-6" :alt="user.name" :src="user.avatar.route" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.name + ' ' + user.lastname }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="
                      $router.push({
                        name: 'administrative/human-talent/UserProfile',
                        params: { userId: user.id },
                      })
                    "
                  >
                    <v-icon> mdi-account-tie </v-icon>
                  </v-btn>
                </template>
                <span>Ver perfil de {{ user.name }}</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
  export default {
    name: 'CharPositionCard',
    data: () => ({
      show: false,
    }),
    props: {
      item: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: false,
      },
    },
  }
</script>
